import store from '../stores'
import _, { isArray } from 'lodash'

import {
  blockActions,
  messageActions
} from '../actions'

const initialState = {
  changed: false,
  blockData: {},
  newId: null,
  fieldPathMapping: {},
  timeouts: {}
}

// Vars
let newState = null

let errorMessage = null

const globalUpdate = {
  sumFromFields: (configs, newState) => {
    configs.forEach(config => {
      let sum = 0

      config.fields.forEach(field => {
        const relatedField = _.get(newState.blockData.blocks, newState.fieldPathMapping[field])

        let tmpValue = relatedField.value

        if (relatedField.options && relatedField.options[relatedField.value] && relatedField.options[relatedField.value].relatedValue) {
          const nval = relatedField.options[relatedField.value].relatedValue[config.relatedValue]

          if (nval || nval === 0) {
            tmpValue = nval
          }
        }

        if (!tmpValue && tmpValue !== 0) {
          return sum
        }

        sum += parseFloat(tmpValue)
      })

      _.set(newState.blockData.blocks, [...newState.fieldPathMapping[config.fieldName], 'value'], sum)
    })
  },

  getSequencesFromFields: (configs, newState) => {
    const validation = (sequence, types, groupValidation) => {
      const defaultType = types.find(item => item.defaultType)

      const validationType = (types.find(type => type.sequences && type.sequences.includes(sequence)) || defaultType).groupType

      return {
        type: validationType,
        value: groupValidation[validationType].value
      }
    }

    const updateFieldFromRelateds = (updateRelatedFieldName, relatedFields, newInputString) => {
      const relatedFieldsValues = []

      relatedFields.forEach(relatedField => {
        const fieldValue = _.get(newState.blockData.blocks, newState.fieldPathMapping[relatedField]).value

        if (fieldValue) {
          relatedFieldsValues.push(fieldValue)
        }
      })

      if (newInputString) {
        relatedFieldsValues.push(newInputString)
      }

      _.set(newState.blockData.blocks,
        [...newState.fieldPathMapping[updateRelatedFieldName], 'value'],
        relatedFieldsValues.join('\n'))
    }

    configs.forEach(config => {
      const groupTypes = []

      config.fieldsGroups.forEach(group => {
        let innerSequence = ''

        group.fields.forEach(field => {
          const relatedField = _.get(newState.blockData.blocks, newState.fieldPathMapping[field])

          const relatedValue = relatedField.relatedValues && relatedField.relatedValues[config.relatedValue] ? relatedField.relatedValues[config.relatedValue].find(el => el.value === relatedField.value) : null

          innerSequence += parseInt(relatedValue ? relatedValue.sum : 0)
        })

        groupTypes.push(validation(innerSequence, group.validation.types, config.groupValidation))
      })

      groupTypes.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))

      const maxValuedSequence = groupTypes[groupTypes.length - 1]

      const newInputString = config.groupValidation[maxValuedSequence.type].label

      _.set(newState.blockData.blocks,
        [...newState.fieldPathMapping[config.fieldName], 'value'],
        newInputString)

      updateFieldFromRelateds(config.updateRelatedFieldName, config.relatedFields, newInputString)
    })
  },

  getTextFromFields: (configs, newState) => {
    configs.forEach(config => {
      let texts = []

      config.fields.forEach(field => {
        const fieldPath = newState.fieldPathMapping[field]

        if (!fieldPath) {
          return
        }

        const relatedField = _.get(newState.blockData.blocks, fieldPath)

        if (relatedField && relatedField.value === true && relatedField.relatedValues && relatedField.relatedValues[config.relatedValue]) {
          texts.push(relatedField.relatedValues[config.relatedValue][0].text)
        }
      })

      if (texts.length > 0 && config.fieldName) {  
        const fieldPath = newState.fieldPathMapping[config.fieldName]

        if (!fieldPath) {
          return
        }

        const existingValue = _.get(newState.blockData.blocks, fieldPath.concat('value'))
        
        const newText = `${config.prefix}${texts.join(', ')}.`
        
        const updatedValue = existingValue ? `${existingValue}\n${newText}` : newText

        _.set(newState.blockData.blocks, fieldPath.concat('value'), updatedValue)
      } else {
        console.log('No texts collected or no fieldName provided, skipping update')
      }
    })
  }
}

const blockReducer = (
  state = initialState,
  action
) => {
  const fieldPath = isArray(action.fieldPath) ? action.fieldPath : [action.fieldPath]

  switch (action.type) {
    case 'block_action_update_values':
      newState = _.cloneDeep(state)

      newState.changed = true

      Object.entries(action.fields).forEach(([key, value]) => {
        const tmpFieldPath = state.fieldPathMapping[key] || null

        if (tmpFieldPath) {
          _.set(newState.blockData.blocks, [...tmpFieldPath, 'value'], value)
        }
      })

      setTimeout(() => {
        return store.dispatch(blockActions.globalUpdate())
      }, 200)

      return {
        ...newState,
        lastUpdate: (new Date()).getTime()
      }
    case 'block_action_global_update':
      Object.values(state.fieldPathMapping).forEach(fieldPath => {
        const currentField = _.get(state.blockData.blocks, fieldPath)

        if (currentField && currentField.globalUpdate) {
          Object.entries(currentField.globalUpdate).forEach(([type, config]) => {
            if (globalUpdate[type]) {
              globalUpdate[type](config, state)
            }
          })
        }
      })

      setTimeout(() => {
        return store.dispatch(blockActions.commitChanges())
      }, 200)

      return {
        ..._.cloneDeep(state)
      }
    case 'block_action_add_to_path_mapping':
      if (action.fieldName) {
        state.fieldPathMapping[action.fieldName] = action.fieldPath
      }

      return state
    case 'block_action_commit_changes':
      newState = _.cloneDeep(state)

      return {
        ...newState
      }
    case 'block_action_block_delete':
      newState = _.cloneDeep(state)

      newState.newId = action.data.data.id

      newState.goTo = action.data.data.goTo || null

      errorMessage = {
        message: action.data.message,
        type: action.data.status === 'success' ? 'success' : 'error',
        autoHide: true
      }

      setTimeout(() => {
        return store.dispatch(messageActions.showMessage(errorMessage))
      }, 200)

      return newState
    case 'block_action_block_save':
      newState = _.cloneDeep(state)

      newState.newId = action.data.data.id

      newState.goTo = action.data.data.goTo || null

      errorMessage = {
        message: action.data.message,
        type: action.data.status === 'success' ? 'success' : 'error',
        autoHide: true
      }

      if (action.data.status === 'success') {
        newState.changed = false
      }

      setTimeout(() => {
        return store.dispatch(messageActions.showMessage(errorMessage))
      }, 200)

      return newState
    case 'block_action_block_get':
      return initialState
    case 'block_action_block':
      return {
        ...state,
        blockData: action.data.data
      }
    case 'block_action_change_field_prop':
      newState = _.cloneDeep(state)

      newState.changed = true

      _.set(newState.blockData.blocks, [...fieldPath, action.prop], action.value)

      return newState
    case 'block_action_change_field':
      state.changed = true

      _.set(state.blockData.blocks, [...fieldPath, 'value'], action.value)

      const field = _.get(state.blockData.blocks, fieldPath)

      if (!field || !field.preventGlobalUpdateOnChange) {
        if (state.timeouts.block_action_global_update) {
          clearTimeout(state.timeouts.block_action_global_update)
        }

        state.timeouts.block_action_global_update = setTimeout(() => {
          store.dispatch(blockActions.globalUpdate())
        }, 200)
      }

      return state
    default:
      return state
  }
}

export default blockReducer
