import store from '../stores'
import { ajaxCall } from '../utils'
import _ from 'lodash'

const { appConfig } = store.getState().appReducer

export const formActions = {
  ajaxCall: (url, params, callback) => {
    ajaxCall({
      config: {
        url: url,
        method: 'get',
        params
      },
      success: (res) => {
        if (typeof callback === 'function') {
          callback(res.data)
        }

        return ({
          type: 'form_action_ajax_call',
          data: res.data
        })
      }
    })

    return ({
      type: 'form_action_ajax_call_get'
    })
  },
  updateOptions: (fieldName, value) => ({
    type: 'form_action_update_options',
    fieldName,
    value
  }),
  formUpdateFieldProps: (value, config) => ({
    type: 'form_action_form_update_field_props',
    value,
    config
  }),
  removeFromList: (index) => ({
    type: 'form_action_remove_from_list',
    index
  }),
  formCalcValue: (global = false) => ({
    type: 'form_action_form_calc_value',
    global
  }),
  commitChanges: fromProp => ({
    type: 'form_action_commit_changes',
    fromProp
  }),
  preppendROValuesFromAjaxPost: (config) => {
    ajaxCall({
      config: {
        url: config.url,
        method: 'post',
        data: config.params
      },
      success: (res) => {
        store.dispatch(formActions.preppendROValuesFromAjax({
          ...config,
          data: res.data.data
        }))
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      ...config,
      type: 'form_action_preppend_ro_values_from_ajax_get'
    })
  },
  preppendROValuesFromAjax: (config) => ({
    type: 'form_action_preppend_ro_values_from_ajax',
    config
  }),
  formAjaxUpdateValuesByName: (data, url, config, params) => {
    ajaxCall({
      config: {
        url: url,
        method: 'post',
        data: data,
        params
      },
      success: (res) => {
        store.dispatch(formActions.formReplaceValues(res.data.data, config))
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'form_action_form_ajax_update_values_by_name'
    })
  },
  formAjaxUpdateValue: (data, fieldIndex, url) => {
    ajaxCall({
      config: {
        url: url,
        method: 'post',
        data: data
      },
      success: (res) => {
        store.dispatch(formActions.formAddValue(res.data.data, fieldIndex))
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'form_action_form_ajax_update_value'
    })
  },
  formSavePost: (formType, params, asNew = false, callback, callbackParams, callbackUrlInterpolation) => {
    const { formContent } = store.getState().formReducer

    const newFormContent = _.cloneDeep(formContent)

    const allowedProps = ['value', 'values', 'name']

    newFormContent.fields.forEach(field => {
      if (field.fields) {
        return field.fields.forEach(innerField => {
          return Object.keys(innerField).forEach(propName => {
            if (allowedProps.indexOf(propName) === -1) {
              delete innerField[propName]
            }
          })
        })
      }

      return Object.keys(field).forEach(propName => {
        if (allowedProps.indexOf(propName) === -1) {
          delete field[propName]
        }
      })
    })

    if (asNew) {
      newFormContent.id = null

      newFormContent.uid = null
    }

    const newParams = _.cloneDeep(params)

    if (params.temporarySave) {
      newParams.isDraft = 1
    }

    delete newParams.temporarySave

    ajaxCall({
      config: {
        url: appConfig.actions[formType][formType + 'SavePost'].path,
        method: 'post',
        data: newFormContent,
        params: newParams
      },
      success: (res) => {
        if (callback && res.data.status === 'success') {
          if (callbackUrlInterpolation && (res.data.data || {}).id) {
            setTimeout(() => {
              callback({
                // eslint-disable-next-line no-template-curly-in-string
                interpolatedUrl: callbackUrlInterpolation.replace('${id}', res.data.data.id)
              })
            }, 100)
          } else {
            setTimeout(() => {
              callback(...callbackParams)
            }, 100)
          } 
        }

        store.dispatch(formActions.formSave(res.data, params.temporarySave))
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'form_action_form_save_post'
    })
  },
  formSave: (data, temporarySave = null) => ({
    type: 'form_action_form_save',
    data,
    temporarySave
  }),
  formGet: (formType, params) => {
    store.dispatch(formActions.form())

    ajaxCall({
      config: {
        url: appConfig.actions[formType][formType + 'Get'].path + (params.id ? '/' + params.id : ''),
        params
      },
      success: (res) => {
        if (res.data.data.rightList) {
          return ajaxCall({
            config: {
              url: res.data.data.rightList
            },
            success: (listRes) => {
              res.data.data.rightList = listRes.data.data

              store.dispatch(formActions.form(res.data))
            }
          })
        }

        store.dispatch(formActions.form(res.data))
      },
      error: () => {},
      activeLoading: true
    })

    return ({
      type: 'form_action_form_get'
    })
  },
  form: (data) => ({
    type: 'form_action_form',
    data
  }),
  formRemoveValue: (value, fieldIndex, dynamicIndex, fieldName) => ({
    type: 'form_action_form_remove_value',
    value,
    fieldIndex,
    dynamicIndex,
    fieldName
  }),
  formReplaceValues: (data, config) => ({
    type: 'form_action_form_replace_values',
    data,
    config
  }),
  formAddValue: (value, fieldIndex, dynamicIndex, fieldName, append) => ({
    type: 'form_action_form_add_value',
    value,
    fieldIndex,
    dynamicIndex,
    fieldName,
    append
  }),
}
