import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MicIcon from '@material-ui/icons/Mic'
import { IconButton } from '@material-ui/core'
import StopIcon from '@material-ui/icons/Stop'

const styles = (theme) => ({
  micIconWrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  micIcon: {
    cursor: 'pointer',
    color: 'white',
    fontSize: 24,
    zIndex: 1,
  },
  micButton: {
    position: 'relative',
    backgroundColor: '#1976d2',
    color: '#fff',
    borderRadius: '50%',
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing.unit,
    '&:hover': {
      backgroundColor: '#0b60b4',
    },
  },
  active: {
    animation: 'pulse 1.5s infinite',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.15)',
    },
    '100%': {
      transform: 'scale(1)',
    }
  },
  errorIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing.unit,
  }
})

class SpeechRecognitionComponent extends React.Component {
  constructor(props) {
    super(props)
    
    this.recognition = null

    this.startListening = this.startListening.bind(this)

    this.stopListening = this.stopListening.bind(this)

    this.startStopListening = this.startStopListening.bind(this)
  }

  componentDidMount() {
    const { options, onChange, handleSpeechError, setSupported } = this.props

    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition

    const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList 

    if (!SpeechRecognition) {
      console.error('Web speech API is not supported.')

      handleSpeechError('Questo browser non supporta la dettatura vocale')

      setSupported(false)

      return
    }

    setSupported(true)

    this.recognition = new SpeechRecognition()

    this.recognition.interimResults = options.interimResults || true

    this.recognition.lang = options.lang || 'it-IT'

    this.recognition.continuous = options.continuous || false

    if (SpeechGrammarList) {
      const grammar = '#JSGF V1.0'

      const speechRecognitionList = new SpeechGrammarList()

      speechRecognitionList.addFromString(grammar, 1)

      this.recognition.grammars = speechRecognitionList
    }

    this.recognition.onresult = (event) => {
      let transcript = ''

      for (let i = 0; i < event.results.length; i++) {
        transcript = Array.from(event.results).map(result => result[0]).map(result => result.transcript).join('')
      }

      const commandRegex = /(inserisci punto|inserisci virgola|inserisci punto e virgola|inserisci spazio|vai a capo)/gi

      transcript = transcript.replace(commandRegex, (match) => {
        switch (match.toLowerCase()) {
          case 'inserisci punto':
            return '.'
          case 'inserisci virgola':
            return ','
          case 'inserisci spazio':
            return ' '
          case 'vai a capo':
            return '\n'
          default:
            return match
        }
      })

      transcript = transcript.replace(/\s([.,])/g, '$1')

      const isFinal = event.results[event.results.length - 1].isFinal

      onChange({
        target: {
          value: transcript,
          isFinal,
          type: 'replace',
          actionType: 'speech'
        }
      })

      if (isFinal) {
        this.recognition.stop()
      }
    }

    this.recognition.onerror = (event) => {
      console.error('Speech recognition error:', event.error)

      if (event.error === 'not-allowed') {
        handleSpeechError(event.error)
      } else {
        handleSpeechError(null)
      }

      this.props.setIsListening(false)
    }

    this.recognition.onend = () => {
      if (this.props.isListening) {
        this.recognition.start()
      } else {
        this.props.setIsListening(false)
      }    
    }
  }

  startListening() {
    if (this.recognition && !this.props.isListening) {
      this.recognition.start()

      this.props.handleSpeechError(null)

      this.props.setIsListening(true)
    }
  }

  stopListening() {
    if (this.recognition && this.props.isListening) {
      this.recognition.abort()

      this.props.setIsListening(false)
    }
  }

  startStopListening() {
    this.props.isListening ? this.stopListening() : this.startListening()
  }

  render() {
    const { classes, isListening } = this.props

    return (
      <div className={classes.micIconWrapper}>
        <IconButton className={`${classes.micButton} ${isListening ? classes.active : ''}`} onClick={this.startStopListening}>
          {isListening ? <StopIcon className={classes.micIcon} /> : <MicIcon className={classes.micIcon} />}
        </IconButton>
      </div>
    )
  }
}

SpeechRecognitionComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.object,
  isListening: PropTypes.bool.isRequired,
  setIsListening: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  handleSpeechError: PropTypes.func.isRequired,
  setSupported: PropTypes.func.isRequired
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SpeechRecognitionComponent)))